import React, { useState } from 'react'
import WorkflowsMainPage from '../Workflows/WorkflowsMainPage'
import OpsDashTicketListPage from '../opsDashTickets/OpsDashTicketListPage'
import { Card, Col } from 'reactstrap'
import SwTeamCreatetask from './Components/SwTeamCreatetask'
import SWTeamTask from './Components/SWTeamTask'

function MyTasksMainPage() {
    const[tabs, setTabs]=useState(3)
    const handleChangeTabs=(value)=>{
        setTabs(value)
    }

  return (
    <>
        <Card className=' p-4 card-of-problemarea'>
           <Col md={12}>
           <div className='d-flex'>
           <h6 className={`${tabs==3?'selected-my-tasks':'not-seletcted-tasks'}`} onClick={()=>handleChangeTabs(3)} ><b>SW Team Tasks</b></h6>
            <h6 className= {`ml-4 ${tabs==1?'selected-my-tasks':'not-seletcted-tasks'}`} onClick={()=>handleChangeTabs(1)}><b>Workflow</b></h6>
            <h6 className={` ml-4 ${tabs ==2?'selected-my-tasks':'not-seletcted-tasks'} `} onClick={()=>handleChangeTabs(2)} ><b>Ticket</b></h6>
           </div>
           </Col>

        </Card>
        {
            tabs===1?
            <WorkflowsMainPage isMyworksScreen={true} />:
            tabs===2?
            <OpsDashTicketListPage isMyworksScreen={true} />:
            tabs===3?
            <SWTeamTask/>:''
            // <SwTeamCreatetask/>:''

        }
    </>
  )
}

export default MyTasksMainPage