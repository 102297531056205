import React, { Fragment, useEffect, useState } from 'react'
import { connect } from "react-redux";
import { propTypes } from 'react-widgets/lib/Calendar';
import StoreHealthService from '../../../../../../Service/OPSServices/StoreHealthServices/StoreHealthService';
import { Container, Col, Row, Card, Input, Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody } from 'reactstrap';
import ReactTable from 'react-table';
import { values } from 'lodash';
import notify from '../../../../../../Notifications/ToastifyActions';
import CustomModal from '../../../../../Components/Modals/CustomModal';
import { Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form } from 'react-bootstrap';
import { OpsGetAllTicketData } from '../../../../../../Service/OPSServices/TicketViewServices/OpsGetAllTicketData';
import { UpdateProblemAreaAndTypeServices } from '../../../../../../Service/OPSServices/TicketViewServices/UpdateProblemAreaAndTypeServices';
import { getEmployeeDataServices } from '../../../../../../Service/OPSServices/TicketViewServices/getEmployeeDataServices';
import { SetInnerHtmlWithClassReplacement } from '../../../../../../Utils/Constants';
import SWAddNewCommentAttachmentService from '../../../../../../Service/OPSServices/TicketViewServices/SWAddNewCommentAttachmentService';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-loaders';

function TicketAssignMainPage(props) {

    const [state, setState] = useState({ isInternalTicket: true, });
    const [text, setText] = useState('');
    const [ticketList, setTicketList] = useState([])
    const [priority, setPriority] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [query, setQuery] = useState('');
    const [assignedToFilter, setAssignedToFilter] = useState('');
    const [selectedProblemArea, setSelectedProblemArea] = useState();
    const [subRequestTypes, setSubRequestTypes] = useState([]);
    const [empolyeeData,setEmpolyeeData] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [assingeTeamMember,setAssingeTeamMember]=useState(false)
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [assingeTeamMemberid,setAssingeTeamMemberid]=useState()
    const [assingeTeamMembername,setAssingeTeamMembername]=useState()

    const [selectedProblemPathWay, setSelectedProblemPathWay] = useState([]);
    const [ticketData,setTicketData]=useState([]);
    const [formData, setFormData] = useState({
        mainRequestType: props.requestTypeID ? props.requestTypeID : "",
        subRequestType: props.requestSubTypeID ? props.requestSubTypeID : "",
        product: props.asinForTicketList ? props.asinForTicketList : "",

        selectedProblemArea: ticketData.problemAreaId?ticketData.problemAreaId:'',
        selectedProblemPathWay: ticketData.problemAreaPathwayId?ticketData.problemAreaPathwayId:'',
        subRequestTypes:ticketData.requestSubTypesId?ticketData.requestSubTypesId:'',

        problemAreaId: props.problemAreaId ? props.problemAreaId : "",
        problemAreaTitle: "",
        problemAreaPathwayId: props.problemAreaPathwayId
            ? props.problemAreaPathwayId
            : "",
        problemAreaPathwayTitle: "",
    });
    const [parea,setParea] = useState(true)
    const [pathway,setPathway] = useState(true)
    const [subtype,setSubtype] = useState(false)
    const [loadingData,setLoadingData] = useState(false)

  useEffect(() => {
        fetchTicketAllData()
        
    },[]);
    const getEmployeeData = (list) =>{
        setLoadingData(true)
        let data={
            problemArea:list ? list[0].problemAreaId:'',
            problemAreaPathway:list ? list[0].problemAreaPathwayId:'',
            subRequestTypes:list ? list[0].requestSubTypesId:''
        }
        console.log(list);
        console.log(data);
        // return true
        getEmployeeDataServices(props.token, data).then((responce)=>{
            if(responce){
                console.log('first')
                setEmpolyeeData(responce)
                setLoadingData(false)
            }
        })
    }
    useEffect(() => {
        if (ticketList.length > 0 && !formData.selectedProblemArea) {
            const defaultData = ticketList[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                selectedProblemArea: defaultData.problemAreaId || '',
                selectedProblemPathWay: defaultData.problemAreaPathwayId || '',
                subRequestTypes: defaultData.requestSubTypesId || '',
            }));
        }
    }, [ticketList]);

  
    const handleQuillChange = (value) => {
        setText(value);
    };
    const handleSelectSubRequestType = (name, id) => {
        const filteredSubRequestTypes = props.subRequestTypes.filter(
            (subType) => subType.problemAreaPathwayId == id
        );
    };

    const handleUpdateSelectedTypes=()=>{
        let id ;

        let data={
            ticketId:ticketData.length > 0?ticketData.ticketId:ticketList[0]?ticketList[0].ticketId:'',
            problemArea:formData.selectedProblemArea,
            problemAreaPathway:formData.selectedProblemPathWay,
            subRequestTypes:formData.subRequestTypes
        }
       
        UpdateProblemAreaAndTypeServices(props.token, data).then((responce)=>{
            if(responce){
                console.log('first')
                console.log(responce)
                setEmpolyeeData(responce)
                fetchTicketAllData()
            }
        })
    }
    
    const replaceIssueDescriptionText = SetInnerHtmlWithClassReplacement(ticketData.issueDescription?ticketData.issueDescription:ticketList[0]?ticketList[0].issueDescription:'');

      const ReadMore = ({ htmlContent, maxLength, id }) => {
    
        const toggleReadMore = () => {
          setIsExpanded(!isExpanded);
        };
    
        const truncatedContent = htmlContent.length > maxLength ? htmlContent.substring(0, maxLength) + '...' : htmlContent;
        return (
          <div>
            <div className="issue-description-set d-flex flex-wrap" dangerouslySetInnerHTML={{ __html: truncatedContent }} />
            {htmlContent.length > maxLength && (
              <span style={{fontSize:'13px'}} onClick={toggleReadMore} className="cursor-pointer d-flex justify-content-end">
                {isExpanded ? ' Read Less' : '....Read More'}
              </span>
            )}
          </div>
        )
      };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setQuery(query);  

        const filtered = ticketList.filter(ticket => {
            console.log(ticket)
            return (
                ticket.storeName.toLowerCase().includes(query.toLowerCase()) ||
                ticket.ticketId.toString().includes(query)
            );
        });

        setFilteredTickets(filtered); 
    };
    const assignTicket = () =>{
        let data = {
            assignTo:assingeTeamMemberid,
            ticketId:ticketData.ticketId?ticketData.ticketId:ticketList[0]?ticketList[0].ticketId:'',
            comment:text?text:'Ticket has been assigned to : ' + assingeTeamMembername
        }
        console.log(data);
        // return true
        SWAddNewCommentAttachmentService(props.token, data).then((res)=>{
            if(res)
            {
                console.log(res);
                setAssingeTeamMemberid()
                setAssingeTeamMembername()
                setEmpolyeeData(res)
                fetchTicketAllData()
            }
        })
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "mainRequestType") {
            handleSelectSubRequestType(name, value);
            setFormData({ ...formData, [name]: value });
        } else if (name === "isInternalTicket") {
            setFormData({ ...formData, [name]: e.target.checked });
        } else {
            if(name == 'selectedProblemArea')
            {
                setPathway(false)
            }
            if(name == 'selectedProblemPathWay')
            {
                setSubtype(false)
            }
            if(name == 'subRequestTypes')
            {
                setPathway(true)
                setParea(true)
                setSubtype(true)
            }
            setFormData({ ...formData, [name]: value });
        }
    };
   
   
    const fetchTicketAllData = async (integerID) => {
        setLoadingData(true)
        let myWorkStatus = false
        let data = {
            integerId: 0,
            myWork: myWorkStatus
        };
        await OpsGetAllTicketData(props.token, data).then((res) => {
            if (res) {
                console.log(res)
                setTicketList(res.ticketList)
                // setTicketData(res.ticketLis?res.ticketLis[0]:'')
                setFilteredTickets(res.ticketList)
                setSelectedProblemArea(res.problemAreaList)
                setSubRequestTypes(res.subRequestTypes)
                setSelectedProblemPathWay(res.problemAreaPathwayList)
                const allList = res.ticketList
                getEmployeeData(allList)
            }
        })

    }
    const handleSetData = (data) => {
        console.log(data)
        setTicketData(data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          selectedProblemArea: data.problemAreaId,
          selectedProblemPathWay: data.problemAreaPathwayId, 
          subRequestTypes: data.requestSubTypesId ,
        }));
      };
      
    const column = [
        {
            Header: 'Id',
            accessor: 'ticketId',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                    <p className="m-0 cursor-pointer"
                     onClick={()=>handleSetData(cellInfo.original)}>{cellInfo.value}</p>
                </div>
            ),
        },
        {
            Header: 'Date',
            accessor: 'ticketCreatedAt',
        },
        {
            Header: 'Store Name',
            accessor: 'storeName',
           
        },
    ]
  
    const handleAssingedTicket=(epdata,flag)=>{
        setAssingeTeamMember(!assingeTeamMember)
        if(flag)
        {
            console.log('true');
            setAssingeTeamMemberid(epdata.id)
            setAssingeTeamMembername(epdata.name)
        }
        else
        {
            console.log('false');
            setAssingeTeamMemberid()
            setAssingeTeamMembername()
        }
    }

    const columns = [
        {
            Header: 'Employee',
            accessor: 'name',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                    {cellInfo.value}
                </div>
            )
        },
        {
            Header: 'Overall Total Tickets',
            accessor: 'overalltotalTicket',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                    {cellInfo.value}
                </div>
            )
        },
        {
            Header: 'Total Tickets',
            accessor: 'totalTicket',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                    {cellInfo.value}
                </div>
            )
        },
        {
            Header: 'Total Stores',
            accessor: 'totalStores',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                    {cellInfo.value}
                </div>
            )
        },
        {
            Header: 'Avg. Acceptance Time',
            accessor: 'avgacceptance',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                    {cellInfo.value}
                </div>
            )
        },
        {
            Header: 'Avg. Resolution Time',
            accessor: 'avgresol',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                    {cellInfo.value}
                </div>
            )
        },
        {
            Header: 'Total Time Spent',
            accessor: 'timespent',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                    {cellInfo.value}
                </div>
            )
        },
        {
            Header: 'Open Tickets Count',
            accessor: 'openticket',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                    {cellInfo.value}
                </div>
            )
        },
        {
            Header: 'Assign Ticket',
            accessor: 'id',
            Cell:(cellInfo)=>(
                <div className="d-flex justify-content-center w-100">
                <input
                type="checkbox"
                value={cellInfo.value}
                checked={(assingeTeamMemberid === cellInfo.value)}
                onChange={(e) => handleAssingedTicket(cellInfo.original,e.target.checked)}
            />
                </div>
            )
        },
    ]
    const filterEmpolyeeData = Array.isArray(empolyeeData) ? empolyeeData.filter((row) => {
        const name = (row.name || '').toString().toLowerCase();
        const assignticket = (row.assignticket || '').toString().toLowerCase();
        const openticket = (row.openticket || '').toString().toLowerCase();
        const timespent = (row.timespent || '').toString().toLowerCase();
        const avgresol = (row.avgresol || '').toString().toLowerCase();
        const totalStores = (row.totalStores || '').toString().toLowerCase();
        const totalTicket= (row.totalStores || '').toString().toLowerCase();
        const avgacceptance = (row.avgacceptance || '').toString().toLowerCase();
        return  totalTicket.includes(searchInput.toString().toLowerCase()) ||totalStores.includes(searchInput.toString().toLowerCase()) || avgresol.includes(searchInput.toString().toLowerCase()) || avgacceptance.includes(searchInput.toString().toLowerCase()) || name.includes(searchInput.toLowerCase()) || assignticket.includes(searchInput.toLowerCase()) || openticket.includes(searchInput.toLowerCase())  || timespent.includes(searchInput.toLowerCase())
    }) : [];
   
    const getRowProps = (state, rowInfo) => {
        if (rowInfo && rowInfo.original) {
          return {
            style: {
              background:
                rowInfo.original.priority === "Onboarding Task" ? ''
                  : rowInfo.original.ticketStatus === "Closed" ? "#cccccc"
                    : rowInfo.original.priority === "Internal Ticket" ? " "
    
                      : rowInfo.original.bgColor,
              color:
                rowInfo.original.ticketStatus === "Closed"
                  ? "#000"
                  : rowInfo.original.bgColor
                    ? "#000"
                    : "#000",
              fontWeight: 300
    
            },
          };
        }
        return {};
      };

      
    return (
        <>
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={6} className='mb-3'>
                            <Card className="p-3 curve-popup">
                                    <Row className="align-items-center mb-2">
                                        <Col md={6}>
                                            <h5><b>New Tickets</b></h5>
                                        </Col>
                                        <Col md={6}>
                                        <input
                                                type="text"
                                                placeholder="Search Text"
                                                value={query}
                                                className="search form-control"
                                                onChange={(e) => handleSearchChange(e) }
                                            />
                                        </Col>
                                    </Row>
                                    <ReactTable
                                        data={filteredTickets}
                                        columns={column}
                                        getTrProps={getRowProps}
                                        getTdProps={(state, rowInfo, column, instance) => {
                    
                                          // Check if the current cell belongs to the first column
                                          //  && rowInfo.original.priority === "Internal Ticket"
                                          if (column.id === "priority" || column.id === 'ticketId') {
                                            if (rowInfo && rowInfo.original.priority === "Internal Ticket") {
                                              return {
                                                style: {
                                                  backgroundColor: '#f7ddee', // Set pink background color for ID 10
                                                  // Add any other styles you need
                                                },
                                              };
                                            }
                                          }
                                          if (column.id === "priority" || column.id === 'ticketId') {
                                            if (rowInfo && rowInfo.original.priority === "Onboarding Task") {
                                              return {
                                                style: {
                                                  backgroundColor: '#96e0e5',
                                                },
                                              };
                                            }
                                          }
                                          return {
                                            // style: {
                                            //   backgroundColor: '#000000', 
                                            // },
                                          };
                                        }}
                                        className="-fixed -highlight -striped"
                                        getTheadProps={() => {
                                          return {
                                            style: {
                                              overflowY: "hidden",
                                              background: "#B1FA63",
                                              color: "#243837",
                                            },
                                          };
                                        }}
                                        defaultPageSize={5}
                                        noDataText={"No Data Found."}
                                      />
                            </Card>
                        </Col>
                        <Col md={6} className='mt-3'>
                        <div >
                            <h5><b> Ticket ID :   {ticketData.ticketId?ticketData.ticketId:ticketList[0]?ticketList[0].ticketId:''}</b></h5>
                                <h5><b>Issue Description:</b></h5>
                                </div>
                            <div className='custom-issue-dis-box' style={{ height: '200px' }}>
                             <ReadMore htmlContent={replaceIssueDescriptionText} maxLength={500} />
                         </div>
                        </Col>
                    </Row>
                </Col>

                <Col md={12} className='mb-3'>
                    <Card className='p-3 curve-popup'>
                            <Row className="align-items-center">
                                <Col md={12} className="mb-1">
                                    <h5><b>Store Name : </b>{ticketData.storeName?ticketData.storeName:ticketList[0]?ticketList[0].storeName:''}</h5>
                                </Col>
                                <Col md={4}>
                                    <div className="d-flex align-items-center">
                                        <label className="mr-1 mb-0">
                                            <h6><b>Problem Area &nbsp;:&nbsp;</b></h6>
                                        </label>
                                        <select
                                            id="selectedProblemArea"
                                            name="selectedProblemArea"
                                            value={formData.selectedProblemArea ? formData.selectedProblemArea :ticketList[0]?ticketList[0].problemAreaId:''}
                                            onChange={handleInputChange}
                                            className="form-control w-50"
                                        >
                                            <option value="" >--Select Problem Area --</option>
                                            {selectedProblemArea
                                                ? selectedProblemArea.map((parea) => (
                                                    <option key={parea.id} value={parea.id}>
                                                        {parea.problemArea}
                                                    </option>
                                                ))
                                                : ""}
                                        </select>
                                    </div>
                                </Col>

                                <Col md={3} className="d-flex align-items-center">
                                            <h6 className="mr-1 "><b>Type &nbsp;:&nbsp;</b></h6>
                                        <select
                                            id="selectedProblemPathWay"
                                            name="selectedProblemPathWay"
                                            value={formData.selectedProblemPathWay?formData.selectedProblemPathWay:1}
                                            onChange={handleInputChange}
                                            className="form-control w-75"
                                        >
                                            <option  value="" >--Select Problem pathway --</option>
                                            {selectedProblemPathWay
                                                ? selectedProblemPathWay.map((type) =>
                                                    formData.selectedProblemArea ==
                                                        type.problemAreaId ? (
                                                        <option key={type.id} value={type.id}>
                                                            {type.problemAreaPathway}
                                                        </option>
                                                    ) : (
                                                        ""
                                                    )
                                                )
                                                : ""}
                                        </select>
                                </Col>

                                <Col md={4} className="d-flex align-items-center ">
                                            <h6 className='mr-1'> <b>Subtype &nbsp;:&nbsp;</b></h6>
                                        <select
                                            id="subRequestTypes"
                                            name="subRequestTypes"
                                            value={formData.subRequestTypes?formData.subRequestTypes:ticketList[0]?ticketList[0].requestSubTypesId:''}
                                            onChange={handleInputChange}
                                            className="form-control w-50"
                                        >
                                            <option value="" disabled= {subtype?true:false} >--Select SubTypes --</option>
                                            {subRequestTypes
                                                ? subRequestTypes.map((subtype) =>
                                                    formData.selectedProblemPathWay ==
                                                        subtype.problemAreaPathwayId ? (
                                                        <option key={subtype.id} value={subtype.id}>
                                                            {subtype.requestSubTypes}
                                                        </option>
                                                    ) : (
                                                        ""
                                                    )
                                                )
                                                : ""}
                                        </select>
                                </Col>

                                <Col md={1} className='d-flex justify-content-end'>
                                <Button
                                    style={{ backgroundColor: "#B2A1FF" }}
                                    onClick={handleUpdateSelectedTypes}
                                    disabled={!((parea === true) && (pathway === true) && (subtype === true))}
                                >
                                    Update
                                </Button>
                                </Col>
                            </Row>
                    </Card>
                </Col>

                <Col md={12}>
                <LoadingOverlay
                    active={loadingData}
                    styles={{
                    overlay: (base) => ({
                        ...base,
                        background: '#f1f4f6',
                        opacity: 1,
                    }),
                    content: (base) => ({
                        ...base,
                        color: '#000',
                    }),
                    }}
                    spinner={<Loader active type="ball-pulse" />}
                    text="Loading asset details..."
                >
                    <Card className='p-3 curve-popup'>
                        <Col md={12} className='d-flex justify-content-between mb-2'>
                        <h5><b>Ticket Assignment Decision data</b></h5>
                        <input
                            type="text"
                            placeholder="Search Text"
                            value={searchInput}
                            className="search form-control w-25"
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                        </Col>
                       
                        <Col md={12}>
                        <ReactTable
                                data={filterEmpolyeeData}
                                columns={columns}
                                noDataText={"No Data Found."}
                                className="-fixed -highlight -striped"
                                defaultPageSize={5}
                                getTheadProps={() => {
                                    return {
                                      style: {
                                        overflowY: "hidden",
                                        background: "#B1FA63",
                                        // color: "#243837",
                                      },
                                    };
                                  }}
                            />
                        </Col>
                           
                    </Card>
                    </LoadingOverlay>
                </Col>
                <Col md={12} className="p-4">
                    <Label htmlFor="Comment">
                        <b>Comments (if any) : </b>
                    </Label>
                    <ReactQuill
                        theme="snow"
                        value={text}
                        onChange={handleQuillChange}
                        placeholder="Please describe your problem"
                    />
                </Col>
                <Col md={12}>
                    <div className="d-flex justify-content-end">
                    <Button
                            style={{ backgroundColor: "#B2A1FF" }}
                            // onClick={() => setIsExpanded(true)}
                            onClick={() => assignTicket(true)}
                        >
                            Assign
                        </Button>
                    </div>
                </Col>
            </Row>
            <CustomModal
                header={"Issue Description"}
                isOpen={isExpanded}
                toggle={() => setIsExpanded(!isExpanded)}
                size={"lg"}
                className="curve-popup"
                footer={
                    <>
                        <Button
                            color={"link"}
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            Cancel
                        </Button>
                    </>
                }
            >
                <div className="modal-body">
                    <ReadMore
                        htmlContent={replaceIssueDescriptionText} 
                    />
                </div>
            </CustomModal>

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        status: state.loggedUser.status,
        error: state.loggedUser.error,
        adProfiles: state.accountOverview.landing.adProfiles,
    };
};

export default connect(mapStateToProps)(TicketAssignMainPage);
